<template>
  <div class="video-module clear">
    <div
      v-masonry
      transition-duration="0s"
      item-selector=".first"
      v-show="listShow"
    >
      <div
        v-masonry-tile
        class="video-module-tab first"
        v-for="(x, i) in textData"
        :key="i"
      >
        <div
          class="video-img"
          @mouseenter="videoGo($event, x)"
          @mouseleave="videoDow($event, x)"
          @click="videoClick($event, x)"
        >
          <img class="video-img-cover" :src="x.coverPhoto" />
          <div
            :class="{
              'video-tonalState': true,
              positive: x.tonalState == 0,
              neutral: x.tonalState == 1,
              negative: x.tonalState == 2,
            }"
          >
            {{ x.tonalState == 2 ? "负" : x.tonalState == 0 ? "正" : "中" }}
          </div>
          <div class="video-warning" v-if="x.isEarlyWarn == 1">警</div>
          <div class="video-type">
            <img
              v-if="x.source.indexOf('快手') != -1"
              src="../../assets/img/videoModule/kuaishou.png"
            />
            <img
              v-if="x.source.indexOf('抖音') != -1"
              src="../../assets/img/videoModule/douying.png"
            />
          </div>
          <el-popover
            placement="bottom"
            popper-class="video-module-popover"
            width="88"
            trigger="hover"
            :visible-arrow="false"
          >
            <ul class="video-more-ul">
              <li @click="dataReport(x)">数据上报</li>
              <li @click="copy(x)">复制全部</li>
              <li @click="quickCopy(x)">复制链接和标题</li>
              <li @click="videoCollect(x)">收藏</li>
              <li @click="deletes(x)">删除</li>
            </ul>
            <div slot="reference" class="video-more">
              <img src="../../assets/img/videoModule/more.png" />
            </div>
          </el-popover>
          <div class="video-collect">
            <img
              src="../../assets/img/videoModule/collect.png"
              v-if="x.isCollect == 0"
            />
            <img
              src="../../assets/img/videoModule/collect2.png"
              v-if="x.isCollect == 1"
            />
          </div>
        </div>
        <div class="video-module-footer" @click="go(x)">
          <p class="video-module-title" v-html="x.title"></p>
          <p class="video-module-time">
            <span style="display: flex; align-items: center">
              <el-popover
                placement="bottom"
                trigger="hover"
                style="display: flex; align-items: center; margin-right: 8px"
              >
                <p>
                  {{ x.accurateId }}
                  <i
                    class="el-icon-document-copy"
                    style="margin-left: 8px; cursor: pointer"
                    @click="myCopy(x.accurateId)"
                  ></i>
                </p>
                <img slot="reference" src="../../assets/img/views/id.png" />
              </el-popover>
              {{ x.author }}
            </span>
            <span>{{ x.releaseTime }}</span>
          </p>
        </div>
      </div>
    </div>
    <div
      v-masonry
      transition-duration="0s"
      item-selector=".second"
      v-show="!listShow"
    >
      <div
        v-masonry-tile
        class="video-module-tab second"
        v-for="(x, i) in listShowList"
        :key="i"
      >
        <div class="video-img-skeleton" :style="{ height: x }">
          <img src="../../assets/img/videoModule/skeleton.png" />
        </div>
        <div class="video-module-footer">
          <el-skeleton-item
            variant="text"
            style="margin-right: 16px; background: #3b3b42"
          />
          <el-skeleton-item
            variant="text"
            style="width: 64%; margin-right: 16px; background: #3b3b42"
          />
          <el-skeleton-item
            variant="text"
            style="width: 30%; background: #3b3b42"
          />
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>
<script>
import {
  accurateList,
  sentimentEdit,
  deleteAccurate,
} from "../../api/accurate/list";
import { dataReport } from "../../api/message/list";
import deleteDialog from "../../components/deleteDialog";
export default {
  components: {
    deleteDialog,
  },
  data() {
    return {
      listShow: false,
      listShowList: [
        "326px",
        "232px",
        "517px",
        "550px",
        "517px",
        "517px",
        "390px",
        "434px",
        "359px",
        "200px",
        "326px",
        "232px",
        "517px",
        "550px",
        "517px",
        "517px",
        "390px",
        "434px",
        "359px",
        "200px",
      ],
      textData: [],
      tablePage: {
        page: 1,
        total: 0,
      },
      drawer: false,
      searchObj: {},
    };
  },
  methods: {
    videoClick(e, x) {
      if (!x.videoPath) {
        this.go(x);
      }
    },
    videoGo(e, x) {
      if (!x.videoPath) {
        e.target.style.cursor = "pointer";
      } else {
        let newVideo = document.createElement("video");
        newVideo.controls = true;
        newVideo.muted = true;
        newVideo.preload = "none";
        newVideo.autoplay = true;
        newVideo.src = x.videoPath;
        newVideo.style.position = "absolute";
        newVideo.style.left = "0";
        newVideo.style.top = "0";
        newVideo.style.width = "100%";
        newVideo.style.height = "100%";
        newVideo.style.borderTopLeftRadius = "8px";
        newVideo.style.borderTopRightRadius = "8px";
        e.target.appendChild(newVideo);
        e.target.children[0].style.opacity = "0";
        e.target.children[0].style.zIndex = "-1";
        if (e.target.children.length == 7) {
          e.target.children[3].style.display = "none";
          e.target.children[5].style.display = "none";
        } else {
          e.target.children[2].style.display = "none";
          e.target.children[4].style.display = "none";
        }
      }
    },
    videoDow(e, x) {
      if (!x.videoPath) {
        return false;
      }
      e.target.removeChild(document.getElementsByTagName("video")[0]);
      e.target.children[0].style.opacity = "1";
      e.target.children[0].style.zIndex = "0";
      if (e.target.children.length == 6) {
        e.target.children[3].style.display = "block";
        e.target.children[5].style.display = "block";
      } else {
        e.target.children[2].style.display = "block";
        e.target.children[4].style.display = "block";
      }
    },
    // 删除
    deletes(item) {
      item.message = "该视频";
      this.$refs.openDialog.openDialog(item);
    },
    async deleteFn(item) {
      const res = await deleteAccurate({
        accurateId: item.accurateId + "",
        dataId: item.dataId + "",
        organId: this.$store.state.user.organizationId,
      });
      this.$log({
        operationModule: "1001102",
        operationDetails: JSON.stringify(item),
        operationRecode: 5,
        requestUrl: res.config.baseURL + res.config.url,
        requestMethod: res.config.method,
        requestBody: JSON.stringify({
          accurateId: item.accurateId + "",
          dataId: item.dataId + "",
          organId: this.$store.state.user.organizationId,
        }),
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
      } else {
        this.$message.error("删除失败！");
      }
    },
    // 复制
    copy(msg) {
      this.$publicFun.copyFun(msg.copy);
      this.$log({
        operationModule: "1001102",
        operationDetails: JSON.stringify(msg),
        operationRecode: 1,
        requestBody: msg.copy,
      });
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
      this.$log({
        operationModule: "1001102",
        operationDetails: JSON.stringify(msg),
        operationRecode: 2,
        requestBody: msg.str,
      });
    },
    // 个别复制
    myCopy(msg) {
      let str = `作者ID:${msg}`;
      this.$publicFun.copyFun(str);
    },
    // 数据上报
    async dataReport(item) {
      let data = {
        infoType: 1,
        inforMsgId: item.dataId,
        organizationId: item.organId,
      };
      const res = await dataReport(data);
      if (res != undefined) {
        if (res.data.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: "warning",
          });
        } else {
          this.$message({
            message: res.data.data.msg,
            type: "success",
          });
        }
        this.$log({
          operationModule: "1001102",
          operationDetails: JSON.stringify(item),
          operationRecode: 8,
          requestUrl: res.config.baseURL + res.config.url,
          requestMethod: res.config.method,
          requestBody: JSON.stringify({
            infoType: 1,
            inforMsgId: item.dataId,
            organizationId: item.organId,
          }),
        });
      }
    },
    // 收藏
    async videoCollect(item) {
      let res = await sentimentEdit({
        accurateId: item.accurateId + "",
        organId: item.organId,
        isCollect: item.isCollect === "0" ? "1" : "0",
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        item.isCollect = item.isCollect === "0" ? "1" : "0";
        this.$log({
          operationModule: "1001102",
          operationDetails: JSON.stringify(item),
          operationRecode: 4,
          requestUrl: res.config.baseURL + res.config.url,
          requestMethod: res.config.method,
          requestBody: JSON.stringify({
            accurateId: item.accurateId + "",
            organId: item.organId,
            isCollect: item.isCollect === "0" ? "1" : "0",
          }),
        });
      }
    },
    //下拉加载列表
    async getList() {
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: 50,
        pageNum: this.tablePage.page,
        startTimeStr: this.$calcu.calcuTime(30) + "T00:00:00+08:00",
        endTimeStr:
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
        videoListFlag: true,
        isCoverPhoto: 1,
      };
      if (this.searchObj.title != "") {
        data.title = this.searchObj.title;
      }
      if (this.searchObj.isEarlyWarn) {
        data.isEarlyWarn = 1;
      }
      if (this.searchObj.dataExist) {
        data.dataExist = 0;
      }

      if (this.searchObj.tonalState) {
        data.tonalState = this.searchObj.tonalState;
      }
      if (this.searchObj.areaInvolved) {
        data.areaInvolved = this.searchObj.areaInvolved;
      }
      if (this.searchObj.mediaAttribute) {
        data.mediaAttribute = this.searchObj.mediaAttribute;
      }
      if (this.searchObj.mediaLevel) {
        data.mediaLevel = this.searchObj.mediaLevel;
      }
      if (this.searchObj.eventClass) {
        data.eventClass = this.searchObj.eventClass;
      }
      const res = await accurateList(data);
      res.data.data.result.forEach((x) => {
        this.textData.push(x);
      });
      this.listShow = true;
    },
    //搜索列表
    async searchList(obj) {
      this.textData = [];
      this.searchObj = obj;
      this.tablePage.page = 1;
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: 50,
        pageNum: this.tablePage.page,
        startTimeStr: this.$calcu.calcuTime(30) + "T00:00:00+08:00",
        endTimeStr:
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
        videoListFlag: true,
        isCoverPhoto: 1,
      };
      if (obj.title != "") {
        data.title = obj.title;
      }
      if (obj.isEarlyWarn) {
        data.isEarlyWarn = 1;
      }
      if (obj.dataExist) {
        data.dataExist = 0;
      }

      if (obj.tonalState) {
        data.tonalState = obj.tonalState;
      }
      if (obj.areaInvolved) {
        data.areaInvolved = obj.areaInvolved;
      }
      if (obj.mediaAttribute) {
        data.mediaAttribute = obj.mediaAttribute;
      }
      if (obj.mediaLevel) {
        data.mediaLevel = obj.mediaLevel;
      }
      if (obj.eventClass) {
        data.eventClass = obj.eventClass;
      }
      const res = await accurateList(data);
      this.textData = res.data.data.result;
    },
    scrolling() {
      let dom = document.getElementsByClassName("video-module")[0];
      let scrollBottom = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
      if (scrollBottom <= 0) {
        this.tablePage.page++;
        this.getList();
      }
    },
    go(x) {
      window.open(x.website, "_blank");
    },
  },
  created() {
    this.getList();
    if (document.getElementById("watermark")) {
      document.getElementById("watermark").remove();
    }
  },
  mounted() {
    document
      .getElementsByClassName("video-module")[0]
      .addEventListener("scroll", this.scrolling);
  },
  beforeDestory() {
    document
      .getElementsByClassName("video-module")[0]
      .removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss" scoped>
.video-more-ul {
  width: 100%;
  padding: 10px 0;
  border-radius: 4px;
  background: #161823;

  li {
    width: 100%;
    height: 30px;
    font-size: 14px;
    color: #c6c6c9;
    line-height: 30px;
    padding-left: 16px;
    cursor: pointer;
    opacity: 0.8;
  }

  li:hover {
    opacity: 1;
  }
}

.video-module {
  width: 100%;
  height: 100%;
  background: #181a25;
  position: relative;
  overflow-y: scroll;

  .video-module-tab {
    width: 316px;
    background: #292a36;
    border-radius: 8px;
    margin: 16px 0 0 16px;
  }

  .video-img-skeleton {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .video-img {
    width: 100%;
    min-height: 150px;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .video-img-cover {
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .video-tonalState {
      position: absolute;
      left: 12px;
      top: 12px;
      padding: 1px 10px;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      z-index: 10;
    }

    .positive {
      background: #07c1ae;
    }

    .neutral {
      background: #3e82ff;
    }

    .negative {
      background: #ea3342;
    }

    .video-warning {
      position: absolute;
      left: 50px;
      top: 12px;
      padding: 1px 10px;
      background: rgba(255, 237, 239, 0.8);
      border-radius: 2px;
      font-size: 14px;
      color: #ea3342;
      z-index: 10;
    }

    .video-type {
      position: absolute;
      left: 12px;
      bottom: 12px;
      cursor: pointer;
      z-index: 10;
    }

    .video-more {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
      z-index: 10;
    }

    .video-collect {
      position: absolute;
      right: 12px;
      bottom: 12px;
      cursor: pointer;
    }
  }

  .video-module-footer {
    width: 100%;
    height: 80px;
    padding: 12px 16px;
    cursor: pointer;

    .video-module-title {
      font-size: 16px;
      color: #eaeaeb;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .video-module-time {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #7d7e85;
    }
  }
}
</style>
